<template>
        <!-- <hr /> -->
        <nav class="three-nav">
            <!-- <div v-on:click="seen = !seen" id="app">
                <li role="menuitem" class="navbar-nav-list hidden-md hidden-lg dropdown xs-design open-side-menu">
                    <a role="link" data-toggle="dropdown" href="#" tabindex="2" class="dropdown-toggle"
                        aria-expanded="false"><i id="ember883" class="gru-icon menu material-icons ember-view">Feature
                        </i></a>
                    <ul class="dropdown-menu">
                        <li class="navbar-nav-list close-menu open">
                            <button data-toggle="dropdown" tabindex="2" class="btn dropdown-toggle"
                                aria-expanded="true"><i id="ember884"
                                    class="gru-icon close material-icons ember-view">close
                                </i></button>
                        </li>
                    </ul>
                </li>
            </div> -->
            <ul class="three-nav navigation-list-item-blk" :class="{'show-navigation-list': seen}">
                <li class="three-nav" @click="receiveConsultation()">
                    <div>
                        <img src="~@/assets/images1/doctor-hospital.png" style="width: 40px;">
                    </div>
                </li>
                <li class="three-nav" @click="getCase()">
                    <div>
                        <img src="~@/assets/images/one-one.png" style="width: 40px;">
                    </div>
                </li>
                <li class="three-nav" @click="offerConsultation()">
                    <div>
                        <img src="~@/assets/images/meet-icon.png" style="width: 40px;">
                    </div>
                </li>
                <li class="three-nav" @click="accessClinical()">
                    <div>
                        <img src="~@/assets/img/medical-knowledge.png" style="width: 40px;">
                    </div>
                </li>
                <li class="three-nav" @click="joinPatient()">
                    <div>
                        <img src="~@/assets/images/patientgroup.png" style="width: 40px;">
                    </div>
                </li>
            </ul>
        </nav>

        <section class="banner-text mt-3 post-consultation">
            <div class=" text-center text-align">
                <div class="d-md-none d-lg-block">
                    <div class="row">
                                <div class="col-12 mt-6 banner-sub">
                                    <h1>Post consultation support</h1>
                                </div>
                            </div>
                    <div class="row" style="margin-left:-50px;">
                        <div class="col-12 mt-5 banner-sub-content">
                            <h3>Time is always a constraint. You are under pressure to see as many</h3>
                            <h3>patients as you to and find time to spend time with your family.</h3>
                        </div>
                        <div class="col-12 mt-3">
                            <h3>We would like to do our bit to help you in your efforts to manage your time.</h3>
                            <h3>Post consultation, we to handled the your patient queries.</h3>
                            <h3>To begin with, we are self-service ability with features to all patients of</h3>
                            <h3>doctors registered with us. There are no charges to your patients.</h3>
                        </div>
                    </div>
                </div>
                </div>
        </section>
                  
        <section class="banner-text mt-3">
            <div class=" text-center text-align">
                <div class="d-md-none d-lg-block">
                    <div class="ul-body" style="margin-top:5rem !important;">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="home-tab" data-bs-toggle="tab"
                                    data-bs-target="#home" type="button" role="tab" aria-controls="home"
                                    aria-selected="true">Patient resource library</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile"
                                    type="button" role="tab" aria-controls="profile" aria-selected="false">Patient
                                    groups</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact"
                                    type="button" role="tab" aria-controls="contact" aria-selected="false">Membership to
                                    your online community</button>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <label disabled>Patients are provided access to an online library of resources published
                                    by medical research institutions in US and Europe. All resources currently available
                                    are in English. These resources are mapped to over 800 topics across specialties.
                                    We are keen to also make available Indian content and that too in Indian languages.
                                    We would be grateful for your suggestions and recommendations.
                                </label>
                            </div>
                            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <label disabled>RxIx offers the option for patients to join disorder-specific groups.
                                    These patient groups operate online. Relevant library of resources is made available
                                    to patients joining these groups. The objective is to support patients to lean on
                                    each other and move forward in their health care journey. Doctors who are part of
                                    these groups can also help in answering the patients' queries.
                                </label>
                            </div>
                            <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                <label disabled>When you signup on RxIx, we create your exclusive online community. Soon
                                    after your consultation, we automatically add the patient to your community. You are
                                    free to publish your wisdom and notes to your community of patients. If you are part
                                    of disorder-specific patient groups, patients may also notice you and join your
                                    community. In addition, your profile page on RxIx will also carry your online
                                    community.
                                </label>
                            </div>
                        </div>
                        <div class="mt-5">
                            <button class="btn btn-blue-color" v-on:click="postCon()" style="color:#ffffff;font-weight: bold;">Submit</button>
                        </div>
                    </div>
                </div>
            </div>

        </section>
        <signinCommonVue @isHide=signupPopupStatus($event) v-if="issubmit"></signinCommonVue>
</template>
<script>
import { defineComponent } from "vue";
import signinCommonVue from "../presignPage/signinCommon.vue";
import axios from "axios"
export default defineComponent({
    components: {
        signinCommonVue
    },
    el: '#hide',
    data() {
        return {
            seen: true,
            postConsult:true,
            currentPath: "",
            issubmit:false
        };
    },
    created: function () {
        this.currentPath = this.$route.path.split('/')[2]
        console.log('this.currentPath',this.currentPath);
     },
    methods: {
        receiveConsultation() { 
      window.scrollTo(0, 0);
      this.$router.push('/doctor/consultationrequests');
        },
        getCase() {
      window.scrollTo(0, 0);
      this.$router.push('/doctor/communities');
        },
        offerConsultation() {
      window.scrollTo(0, 0);
      this.$router.push('/doctor/offerconsultation');
        },
        accessClinical() {
      window.scrollTo(0, 0);
      this.$router.push('/doctor/clinicalresources');
        },
        joinPatient() {
      window.scrollTo(0, 0);
      this.$router.push('/doctor/patientgroups');
        },
        signupPopupStatus(event) {
      if (event) {
        this.issubmit = false;
      }
    },
        async postCon(){
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            this.issubmit=true
            let payload = {}
            await axios
                .put(`${BASE_API_URL}/hcps/generate-otp`, payload, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then(response => {
                    if (response.status == 200) {
                        this.postConsult = !this.postConsult;
                        this.postConsult = response.data;
                        // window.location.href = `/index/${this.currentPath}`;
                    }
                })
                .catch(error => {
                    console.log(error?.response)
                        .then(() => {
                            if (error?.response.status == 404) {
                                this.postConsult = false;
                            }
                            else if (error?.response.status == 400) {
                                return false;
                            }
                        });
                });
        }
    },
    name: "HomePageB",
    props: {},
});

</script>
<style>
.h3,
h3 {
    font-size: calc(1.5rem + 0.2vw);
    margin-left: 40px;
    width: inherit;
}

.tab-content>.active {
    display: inline-block;
    /* border: 1px solid black; */
    width: 65% !important;
    background-color: #fff;
}
@media screen and (max-width:1500px) {
    .tab-content>.active {
    display: inline-block;
    /* border: 1px solid black; */
    width: 88% !important;
    background-color: #fff;
}
}

label {
    margin: 15px;
    font-size: 25px;
}

.navigation-list-item-blk {
    transition: 0.3s;
    /* transform: translateX(-100px); */
    transform: translateX(75px);
}

.show-navigation-list {
    transform: translateX(0px);
}

button.presign.btn.btn-blue-color.text-white {
    float: right;
    /* width: auto; */
    width: 40%;
    font-weight: bold;
    font-size: large;
    /* margin: 10px -60px 10px 0px; */
}

.rxix-menu li.nav-item a.nav-link {
    color: #00979e;
}

nav.navbar.navbar-expand-lg.navbar-light {
    position: fixed;
    width: 100%;
    z-index: 9999;
    top: 0;
}

section.banner-text.mt-3.post-consultation{
    margin-top: 5rem !important;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/cards-banner7.png");
    background-size: 3500px 593px;
    padding: 30px 0px 50px 0px;
}
.banner-sub{
    text-align:center;
    margin-top:35px;
    color:#000000;
}
.banner-sub-content {
    margin-top: 20px !important;
}

button.btn.btn-action {
    background: #00979e;
    color: #fff;
    width: 100%;
    border-radius: inherit;
}

.col-box {
    display: flex;
    justify-content: center;
    margin: 40px 40px 0px 40px
}

/* @media screen and (max-width:991px) {
    .col-12.mt-5 {
        width: 85%;
        margin-left: 10%;
    }
}

@media screen and (max-width:991px) {
    .col-12.mt-3 {
        width: 88%;
        margin-left: 8%;
    }
} */

@media screen and (max-width:991px) {
    .col-box {
        display: flex;
        margin: auto;
    }
}

.btn-text {
    text-align: center;
    margin: 25px;
    padding: 25px;
    color: #00979e;
}

@media screen and (max-width:991px) {
    .btn-text {
        text-align: center;
        margin: 20px;
        padding: 20px;
        color: #00979e;
    }
}

.col-4.mt-3 {
    width: 25%;
}

button.btn.btn-blue-color.text-white {
    width: 100%;
}

button.offer.btn.btn-blue-color.text-white {
    width: auto;
    font-weight: bold;
    font-size: large;
    margin-bottom: 30px;
}

li.nav-item {
    margin: 15px;
}

ul.nav.nav-tabs {
    justify-content: center;
    font-size: 20px;
}

.nav-tabs {
    border-bottom: 0px !important;
}

.nav-tabs .nav-link.active {
    color: white;
    background-color: #00979e;
    border-color: #dee2e6 #dee2e6 #fff;
    font-size:30px;
}

.nav-tabs .nav-link {
    color: black;
    /* margin-bottom: -1px; */
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    font-size:30px;
}


a.nav-link.active {
    width: 200px;
    height: 65px;
    font-size: large;
    text-align: center;
}

.ul-body {
    position: relative;
    bottom: 20px;
}

textarea {
    display: block;
    width: 80%;
    height: 200px;
    margin: auto;
    padding: 0.375rem 0.75rem;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #00979e;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

:after {
    content: "";
}

/* nav.three-nav {
    float: right;
    position: relative;
    top: 52px;
    left: 70px;
    background: transparent;
} */
nav.three-nav {
    float: right;
    position: fixed;
    right: -3px;
    background: transparent;
}

@media screen and (max-width:992px) {
    nav.three-nav {
        float: right;
        position: fixed;
        right: 0;
        background: transparent;
    }
}

nav.three-nav:hover {
    transform: perspective(400px) rotateY(1deg);
}

nav.three-nav ul.three-nav {
    text-align: center;
    /* margin-left: -72px; */
    margin-top: 35px;
    /* margin-top: 77px; */
}
@media screen and (max-width:992px) {
    nav.three-nav ul.three-nav {
    text-align: center;
    /* margin-left: -72px; */
    margin-top: 0px
    /* margin-top: 77px; */
}
}

nav.three-nav ul.three-nav li.three-nav {
    position: relative;
    width: 71px;
    cursor: pointer;
    background: cadetblue;
    text-transform: uppercase;
    transition: all .4s ease-out;
    list-style: none;
}

nav.three-nav ul.three-nav li:after {
    position: absolute;
    background: white;
    color: crimson;
    top: 0;
    /* right: 70px; */
    left: 0px;
    width: 70px;
    height: 100%;
    opacity: .5;
    transform: perspective(400px) rotateY(90deg);
    transform-origin: 0 100%;
    transition: all .4s ease-out;
}

nav.three-nav ul.three-nav li:nth-child(1):after {
    /* content: "dcba"; */
    line-height: 88px;
}

nav.three-nav ul.three-nav li:nth-child(2):after {
    /* content: "get"; */
    line-height: 88px;
}

nav.three-nav ul.three-nav li:nth-child(3):after {
    /* content: "offer"; */
    line-height: 85px;
}

nav.three-nav ul.three-nav li:nth-child(4):after {
    /* content: "access"; */
    line-height: 70px;
}

nav.three-nav ul.three-nav li:nth-child(5):after {
    /* content: "join"; */
    line-height: 85px;
}

nav.three-nav ul.three-nav li:hover {
    /* transform: translateX(0px); */
    /* transform: translateX(70px); */
    /* transform: rotateY(-360deg); */
    transform: translateX(0px);
    background-color: #fff;
}

nav.three-nav ul.three-nav li:hover:after {
    opacity: 1;
    /* transform: perspective(400px) rotateY(0deg) scale(1); */
    /* transform: perspective(400px) rotateY(180deg) scale(1); */
    transform: perspective(400px) rotateY(90deg) scale(1);
}


nav.three-nav ul.three-nav li>div {
    display: inline-block;
    padding: 25px 0;
    background: transparent;
}

nav.three-nav ul.three-nav li.three-nav div {
    position: relative;
}


li.navbar-nav-list.hidden-md.hidden-lg.dropdown.xs-design.open-side-menu {
    list-style: none;
    position: relative;
    left: 35px;
    /* right: 35px; */
    /* top: 200px; */
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0 solid !important;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
</style>